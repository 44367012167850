<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  "
>
  <mat-card style="display: inline-block" class="sign-up-card">
    <div style="display: flex;align-items: center;justify-content: center;">
      <img [src]="logo" alt="user-image" class="logo" />
    </div>
    <div fxLayout="row wrap">
      <div lass="p-2">
        <h2 class="text-muted text-center">Sign Up</h2>
        <br/>
        <form
          [formGroup]="registerForm"
          (submit)="onRegisterFormSubmit(registerForm.value)"
        >
          <!-- <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>Username</mat-label>
            <input
              matInput
              placeholder="Username"
              formControlName="UserName"
              required
            />
            <mat-error *ngIf="registerForm.controls.UserName.errors?.required"
              >Username is required</mat-error
            >
          </mat-form-field> -->
          <label id="example-radio-group-label">Firm Type : &nbsp;</label>
          <mat-radio-group aria-labelledby="example-radio-group-label"  formControlName="firmType" class="example-radio-group">
              <label for="individual" class="radio-inline"></label>
              <mat-radio-button  color="primary" id="individual" class="example-radio-button"  value="individual" (change)="onItemChange($event)">
                Individual
              </mat-radio-button>
              &nbsp;
              <label for="company" class="radio-inline"></label>
              <mat-radio-button color="primary" id="company" class="example-radio-button"  value="company" (change)="onItemChange($event)">
                Company
              </mat-radio-button>
          </mat-radio-group>
          <br/>
          <div *ngIf="registerForm.controls.firmType.value=='company'">
            <div style="margin-top: 15px;">
              <label style="font-weight: 500;">Firm Details</label>
            </div>
            <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmName</mat-label>
            <input
              matInput
              placeholder="FirmName"
              formControlName="FirmName"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmName.errors?.required"
              >FirmName is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmRegNumber</mat-label>
            <input
              matInput
              placeholder="FirmRegNumber"
              formControlName="FirmRegNumber"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmRegNumber.errors?.required"
              >FirmRegNumber is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmBranchName</mat-label>
            <input
              matInput
              placeholder="FirmBranchName"
              formControlName="FirmBranchName"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmBranchName.errors?.required"
              >FirmBranchName is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmOfficeAddress</mat-label>
            <input
              matInput
              placeholder="FirmOfficeAddress"
              formControlName="FirmOfficeAddress"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmOfficeAddress.errors?.required"
              >FirmOfficeAddress is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmPhoneNumber</mat-label>
            <input
              matInput
              placeholder="FirmPhoneNumber"
              formControlName="FirmPhoneNumber"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmPhoneNumber.errors?.required"
              >FirmPhoneNumber is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>FirmEmailIid</mat-label>
            <input
              matInput
              placeholder="FirmEmailIid"
              formControlName="FirmEmailIid"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirmEmailIid.errors?.required"
              >FirmEmailIid is required</mat-error
            >
          </mat-form-field>
          <label style="font-weight: 500;">Personal  Details</label>

          </div>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>First Name</mat-label>
            <input
              matInput
              placeholder="First Name"
              formControlName="FirstName"
              required
            />
            <mat-error *ngIf="registerForm.controls.FirstName.errors?.required"
              >First Name is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-2">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              placeholder="Last Name"
              formControlName="LastName"
              required
            />
            <mat-error *ngIf="registerForm.controls.LastName.errors?.required"
              >Last Name is required</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              required
            />
            <mat-error *ngIf="registerForm.controls.email.errors?.required"
              >Email is required</mat-error
            >
            <mat-error
              *ngIf="registerForm.controls.email.hasError('invalidEmail')"
              >Invalid email address</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-label>Mobile Number</mat-label>
            <input
              matInput
              placeholder="Mobile Number"
              formControlName="MobileNo"
              required
            />
            <mat-error *ngIf="registerForm.controls.MobileNo.errors?.required"
              >Mobile No. is required</mat-error
            >
            <mat-error *ngIf="registerForm.controls.MobileNo.errors?.pattern"
              >Invalid mobile number</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-label>Password</mat-label>
            <input
              matInput
              placeholder="Password"
              formControlName="password"
              type="password"
              minlength="6"
              required
            />
            <mat-error *ngIf="registerForm.controls.password.errors?.required"
              >Password is required</mat-error
            >
            <mat-error
              *ngIf="registerForm.controls.password.hasError('minlength')"
              >Password isn't long enough, minimum of 6 characters</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100 mt-1">
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              placeholder="Confirm Password"
              formControlName="confirmPassword"
              type="password"
              required
            />
            <mat-error
              *ngIf="registerForm.controls.confirmPassword.errors?.required"
              >Confirm Password is required</mat-error
            >
            <mat-error
              *ngIf="
                registerForm.controls.confirmPassword.hasError(
                  'mismatchedPasswords'
                )
              "
              >Passwords do not match</mat-error
            >
          </mat-form-field>
          <div class="text-center mt-2">
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="mat-elevation-z6"
              (click)="onRegisterFormSubmit(registerForm.value)"
            >
              <!-- <mat-icon>person_add</mat-icon> -->
              Submit
            </button>
          </div>
        </form>
        <h3 class="text-muted text-center mt-2" routerLink="/sign-in" style="cursor: pointer;">Back To Login</h3>

      </div>
    </div>
  </mat-card>
</div>
