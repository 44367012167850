import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { SignInServiceService } from "../sign-in-service.service";
import { emailValidator } from "src/app/theme/utils/app-validators";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-reset-password-confirm",
  templateUrl: "./reset-password-confirm.component.html",
  styleUrls: ["./reset-password-confirm.component.scss"],
})
export class ResetPasswordConfirmComponent implements OnInit {
  resetForm: FormGroup;
  logo = "assets/images/others/agvision_logo.png";

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    public _loginService: SignInServiceService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let email_add: any;
    this.route.queryParams.subscribe((params) => {
      email_add = params["email"];
    });

    this.resetForm = this.formBuilder.group(
      {
        otp: [
          "",
          Validators.compose([Validators.required, Validators.minLength(4)]),
        ],
        email: [
          email_add ? email_add : "",
          Validators.compose([Validators.required, emailValidator]),
        ],
        password: ["", Validators.required],
        password1: ["", Validators.required],
      }
      // { validator: matchingPasswords("password", "confirmPassword") }
    );
  }
  public onResetFormSubmit(values: Object): void {
    this.spinner.show();
    if (this.resetForm.valid) {
      this._loginService.user_reset_password_confirm(this.resetForm.value).subscribe(
        (data: any) => {
          this.spinner.hide();
          this.router.navigate(["/sign-in"]);
          this.snackBar.open("Your account password changed successfully!", "×", {
            panelClass: "success",
            verticalPosition: "top",
            duration: 3000,
          });
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log("error", error);
          if (error?.error[0]) {
            this.snackBar.open(error?.error[0], "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          } else {
            this.snackBar.open("Something went wrong!", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          }
        }
      );
    } else {
      this.resetForm.markAllAsTouched();
      this.spinner.hide();
      return;
    }
  }
}
