import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { SignInServiceService } from "../sign-in-service.service";
import { emailValidator } from "src/app/theme/utils/app-validators";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-activate-user",
  templateUrl: "./activate-user.component.html",
  styleUrls: ["./activate-user.component.scss"],
})
export class ActivateUserComponent implements OnInit {
  activationForm: FormGroup;
  logo="assets/images/others/agvision_logo.png"

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    public _loginService: SignInServiceService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService


  ) {}

  ngOnInit(): void {
    let email_add :any
    this.route.queryParams.subscribe(params => {
      email_add = params['email'];
    });
    

    this.activationForm = this.formBuilder.group(
      {
        otp: [
          "",
          Validators.compose([Validators.required, Validators.minLength(4)]),
        ],
        email: [email_add?email_add:"", Validators.compose([Validators.required, emailValidator])],
      }
      // { validator: matchingPasswords("password", "confirmPassword") }
    );
  }
  public onActivationFormSubmit(values: Object): void {
    this.spinner.show()
    if (this.activationForm.valid) {
      this._loginService.user_activate(this.activationForm.value).subscribe(
        (data: any) => {
          this.spinner.hide()
          this.router.navigate(["/sign-in"]);
          this.snackBar.open("Your account activated successfully!", "×", {
            panelClass: "success",
            verticalPosition: "top",
            duration: 3000,
          });
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide()
          console.log("error", error);
          if(error?.error[0]){
            this.snackBar.open(error?.error[0], "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          }else{
            this.snackBar.open("Something went wrong!", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          }
        }
      );
    }
    else{
      this.activationForm.markAllAsTouched()
      this.spinner.hide()
      return
    }
  }
}
