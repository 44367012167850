import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SignInServiceService } from "../sign-in-service.service";
import {
  emailValidator,
} from "src/app/theme/utils/app-validators";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  registerForm: FormGroup;
  logo = "assets/images/others/agvision_logo.png";

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    public _loginService: SignInServiceService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        // UserName: [
        //   "",
        //   Validators.compose([Validators.required, Validators.minLength(3)]),
        // ],
        firmType: ["individual", Validators.required],

        FirmName: [""],
        FirmRegNumber: [""],
        FirmBranchName: [""],
        FirmOfficeAddress: [""],
        FirmPhoneNumber: [""],
        FirmEmailIid: [""],

        email: ["", Validators.compose([Validators.required, emailValidator])],
        MobileNo: [
          "",
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        LastName: ["", Validators.required],
        FirstName: ["", Validators.required],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      }
      // { validator: matchingPasswords("password", "confirmPassword") }
    );
  }
  onItemChange(event) {
    if (event.value == "company") {
      this.registerForm.get("FirmName").setValidators([Validators.required]);
      this.registerForm
        .get("FirmRegNumber")
        .setValidators([Validators.required]);
      this.registerForm
        .get("FirmBranchName")
        .setValidators([Validators.required]);
      this.registerForm
        .get("FirmOfficeAddress")
        .setValidators([Validators.required]);
      this.registerForm
        .get("FirmPhoneNumber")
        .setValidators([Validators.required]);
      this.registerForm
        .get("FirmEmailIid")
        .setValidators([Validators.required]);
      this.registerForm.get("FirmName").updateValueAndValidity();
      this.registerForm.get("FirmRegNumber").updateValueAndValidity();
      this.registerForm.get("FirmBranchName").updateValueAndValidity();
      this.registerForm.get("FirmOfficeAddress").updateValueAndValidity();
      this.registerForm.get("FirmPhoneNumber").updateValueAndValidity();
      this.registerForm.get("FirmEmailIid").updateValueAndValidity();
    } else {
      this.registerForm.get("FirmName").clearValidators();
      this.registerForm.get("FirmRegNumber").clearValidators();
      this.registerForm.get("FirmBranchName").clearValidators();
      this.registerForm.get("FirmOfficeAddress").clearValidators();
      this.registerForm.get("FirmPhoneNumber").clearValidators();
      this.registerForm.get("FirmEmailIid").clearValidators();
      this.registerForm.get("FirmName").updateValueAndValidity();
      this.registerForm.get("FirmRegNumber").updateValueAndValidity();
      this.registerForm.get("FirmBranchName").updateValueAndValidity();
      this.registerForm.get("FirmOfficeAddress").updateValueAndValidity();
      this.registerForm.get("FirmPhoneNumber").updateValueAndValidity();
      this.registerForm.get("FirmEmailIid").updateValueAndValidity();
    }
  }
  public onRegisterFormSubmit(values: Object): void {

    this.spinner.show();
    if (this.registerForm.valid) {
      this._loginService.user_register(this.registerForm.value).subscribe(
        (data: any) => {
          this.spinner.hide();

          this.router.navigate(["/activate-user"], {
            queryParams: { email: this.registerForm.value.email },
          });

          this.snackBar.open("You registered successfully!", "×", {
            panelClass: "success",
            verticalPosition: "top",
            duration: 3000,
          });
        },
        (error: HttpErrorResponse) => {
          this.spinner.hide();

          console.log("error", error);
          if (error?.error[0]) {
            this.snackBar.open(error?.error[0], "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          } else if (error?.error?.email[0]) {
            this.snackBar.open("This email already exists!", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          } else {
            this.snackBar.open("Something went wrong!", "×", {
              panelClass: "error",
              verticalPosition: "top",
              duration: 3000,
            });
          }
        }
      );
    } else {
      this.spinner.hide();
      this.registerForm.markAllAsTouched()
      this.snackBar.open("Please fill all mandatory fields!", "×", {
        panelClass: "error",
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }
}
