import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { SignUpComponent } from "./pages/sign-in/sign-up/sign-up.component";
import { ActivateUserComponent } from "./pages/sign-in/activate-user/activate-user.component";
import { ResetPasswordComponent } from "./pages/sign-in/reset-password/reset-password.component";
import { ResetPasswordConfirmComponent } from "./pages/sign-in/reset-password-confirm/reset-password-confirm.component";
import { AuthGuard } from "./authentication/auth.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "sign-in",
    pathMatch: "full",
  },
  {
    path: "home",
    component: PagesComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "account",
        loadChildren: () =>
          import("./pages/account/account.module").then((m) => m.AccountModule),
        data: { breadcrumb: "Account Settings" },
      },
      {
        path: "compare",
        loadChildren: () =>
          import("./pages/compare/compare.module").then((m) => m.CompareModule),
        data: { breadcrumb: "Compare" },
      },
      {
        path: "wishlist",
        loadChildren: () =>
          import("./pages/wishlist/wishlist.module").then(
            (m) => m.WishlistModule
          ),
        data: { breadcrumb: "Wishlist" },
      },
      {
        path: "cart",
        loadChildren: () =>
          import("./pages/cart/cart.module").then((m) => m.CartModule),
        data: { breadcrumb: "Cart" },
      },
      {
        path: "checkout",
        loadChildren: () =>
          import("./pages/checkout/checkout.module").then(
            (m) => m.CheckoutModule
          ),
        data: { breadcrumb: "Checkout" },
      },
      {
        path: "contact",
        loadChildren: () =>
          import("./pages/contact/contact.module").then((m) => m.ContactModule),
        data: { breadcrumb: "Contact" },
      },
      {
        path: "brands",
        loadChildren: () =>
          import("./pages/brands/brands.module").then((m) => m.BrandsModule),
        data: { breadcrumb: "Brands" },
      },
      {
        path: "products",
        loadChildren: () =>
          import("./pages/products/products.module").then(
            (m) => m.ProductsModule
          ),
        data: { breadcrumb: "All Products" },
      },
    ],
    canActivate: [AuthGuard],
  },
  { path: "sign-up", component: SignUpComponent },
  { path: "activate-user", component: ActivateUserComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "reset-password-confirm", component: ResetPasswordConfirmComponent },

  {
    path: "sign-in",
    loadChildren: () =>
      import("./pages/sign-in/sign-in.module").then((m) => m.SignInModule),
  },
  {
    path: "landing",
    loadChildren: () =>
      import("./landing/landing.module").then((m) => m.LandingModule),
    canActivate: [AuthGuard],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      initialNavigation: "enabledBlocking", // for one load page, without reload
      // useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
