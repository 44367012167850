<div
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  "
>
  <mat-card style="display: inline-block;width: 40%;">
    <div style="display: flex;align-items: center;justify-content: center;">
        <img [src]="logo" alt="user-image" class="logo" />
      </div>
    <div fxLayout="row wrap" style="display: flex;align-items: center;justify-content: center;width: 100%;">
      <div class="p-2" style="width: 100%;">
        <h2 class="text-muted text-center">Reset Your Account Password!</h2>
        <form
          [formGroup]="resetForm"
        >
          <mat-form-field appearance="outline" class="w-100 mt-2" > 
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              
              required
            />
            <mat-error *ngIf="resetForm.controls.email.errors?.required"
              >Email is required</mat-error
            >
            <mat-error
              *ngIf="resetForm.controls.email.hasError('invalidEmail')"
              >Invalid email address</mat-error
            >
          </mat-form-field>
         

          <div class="text-center mt-2">
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="mat-elevation-z6"
              (click)="onResetFormSubmit(resetForm.value)"
            >
              Get OTP
            </button>
          </div>
        </form>
        <!-- <h3 class="text-muted text-center mt-2" routerLink="/sign-in" style="cursor: pointer;">Back To Login</h3> -->
      </div>
    </div>
  </mat-card>
</div>
