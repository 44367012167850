import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class SignInServiceService {
  host = environment.APIUrl;

  // private login = "admin-login";
  private login = "login";
  private register = "register";
  private activate = "activate";
  private reset_password="reset-password";
  private reset_password_confirm="reset-password-confirm";

  constructor(private http: HttpClient) {}

  user_login(data) {
    return this.http.post(this.host + this.login, data);
  }
  user_register(data) {
    return this.http.post(this.host + this.register, data);
  }
  user_activate(data) {
    return this.http.post(this.host + this.activate, data);
  }
  user_reset_password(data) {
    return this.http.post(this.host + this.reset_password, data);
  }
  user_reset_password_confirm(data) {
    return this.http.post(this.host + this.reset_password_confirm, data);
  }
}
